import isURL from 'validator/lib/isURL';

export const validateUrl = url => {
  return url ? isURL(url) : false;
};

export const validateFullUrl = url => {
  const regex = new RegExp(
    /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  );
  return url ? isURL(url) && regex.test(url) : false;
};
