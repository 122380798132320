import { createContext, useState } from 'react';

export const SnackbarContext = createContext();

export const SnackBarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState();
  const value = {
    snackbar,
    setSnackbar,
  };
  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};
