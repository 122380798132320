import Promise from 'bluebird';
import { convertPunctuationToBackslash } from './convertPunctuationToBackslash';

export const getFilesFromEvent = async event =>
  Promise.map(
    event.target.files || event.dataTransfer.files,
    async file =>
      new Promise(resolve => {
        file = new File([file], convertPunctuationToBackslash(file.name), {
          type: file.type,
        });

        if (file.type.startsWith('image')) {
          const image = new Image();
          image.onload = () => {
            file.width = image.width;
            file.height = image.height;

            resolve(file);
          };
          const url = URL.createObjectURL(file);
          image.src = url;
          return file;
        } else if (file.type.startsWith('video')) {
          const video = document.createElement('video');
          video.preload = 'metadata';

          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            file.duration = video.duration;
            file.width = video.videoWidth;
            file.height = video.videoHeight;

            resolve(file);
          };

          video.src = URL.createObjectURL(file);
          return file;
        } else if (file.type.startsWith('audio')) {
          const reader = new FileReader();
          const audio = document.createElement('audio');
          reader.onload = e => {
            audio.src = e.target.result;
            audio.addEventListener(
              'durationchange',
              () => {
                file.duration = audio.duration;
                resolve(file);
              },
              false,
            );
          };
          reader.readAsDataURL(file);
          return file;
        }
        return file;
      }),
  );
