export const descendingComparator = (a, b, orderBy) => {
  if (a[orderBy] === undefined || a[orderBy] === null) {
    if (b[orderBy] === undefined || b[orderBy] === null) return 0;
    return 1;
  } else if (b[orderBy] === undefined || b[orderBy] === null) return -1;

  if (a[orderBy] === Infinity) return -1;
  if (b[orderBy] === Infinity) return -1;

  if (typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') {
    if (!isNaN(a[orderBy])) {
      if (!isNaN(b[orderBy])) return +a[orderBy] > +b[orderBy] ? -1 : 1;
      return 1;
    }
    if (b[orderBy]?.toUpperCase() < a[orderBy]?.toUpperCase()) return -1;
    if (b[orderBy]?.toUpperCase() > a[orderBy]?.toUpperCase()) return 1;
  }

  const orderByForA = isNaN(a[orderBy]) ? -1 : a[orderBy];
  const orderByForB = isNaN(b[orderBy]) ? -1 : b[orderBy];

  if (parseFloat(a[orderBy]) && parseFloat(b[orderBy])) {
    if (parseFloat(orderByForB) < parseFloat(orderByForA)) return -1;
    if (parseFloat(orderByForB) > parseFloat(orderByForA)) return 1;
  }

  if (orderByForB < orderByForA) return -1;
  if (orderByForB > orderByForA) return 1;

  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};
