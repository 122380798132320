import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';

const ListItemHistory = ({ title, renderChild }) => {
  return (
    <>
      {title ? (
        <ListItem alignItems="center" justifyContent="center">
          <ListItemText
            primary={
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{title}</Typography>
                </Grid>
                <Grid item container spacing={5}>
                  <Grid item xs={1}></Grid>
                  <Grid item container xs={10}>
                    {renderChild}
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </ListItem>
      ) : (
        <></>
      )}
    </>
  );
};

export default ListItemHistory;
