import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import Menu from '@audiencetown/owk-uitk/dist/components/Menu';
import MenuItem from '@audiencetown/owk-uitk/dist/components/MenuItem';
import IconButton from '@audiencetown/owk-uitk/dist/components/IconButton';
import { MoreVert } from '@audiencetown/owk-uitk/dist/icons';

const useStyles = makeStyles(() => ({
  IconButton: {
    padding: '0rem',
    marginTop: '0.375rem',
  },
}));

const NotificationItemActions = ({ id, onActionHandler }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleOptions = e => {
    setAnchorEl(e.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = async e => {
    setAnchorEl(null);
    onActionHandler('delete', id);
  };
  const handleUnread = async e => {
    setAnchorEl(null);
    onActionHandler('unread', id);
  };

  return (
    <>
      <IconButton
        className={classes.IconButton}
        onClick={e => {
          handleOptions(e);
        }}>
        <MoreVert color="secondary" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleUnread}>Mark as unread</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default NotificationItemActions;
