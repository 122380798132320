import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Avatar from '@audiencetown/owk-uitk/dist/components/Avatar';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import Link from '@audiencetown/owk-uitk/dist/components/Link';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import { UpdateOutlined } from '@audiencetown/owk-uitk/dist/icons';
import { getTimeInterval } from '../services/notifications.service';
import NotificationItemActions from './notificationItemAction';
import NotificationUserName from './NotificationUserName';

const useStyles = makeStyles(() => ({
  smallAvatar: {
    width: '1.5rem',
    height: '1.5rem',
  },
  itemAvatar: {
    minWidth: '2rem',
    marginTop: '0.375rem',
  },
  listItem: {
    paddingLeft: '0rem',
  },
  incompleteText: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  fullText: {
    'word-wrap': 'break-word',
    'overflow-wrap': 'break-word',
  },
}));

const StaleCampaignNotificationItem = ({
  onActionHandler,
  item,
  setIsPulled,
}) => {
  const navigate = useNavigate();
  const navigateTo = id => navigate(`/campaigns/${id}`);

  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      alignItems="flex-start"
      key={item?.id}>
      <ListItemAvatar className={classes.itemAvatar}>
        {item?.from ? (
          <Avatar
            className={classes.smallAvatar}
            src={item?.rawData?.user?.img ?? ''}
          />
        ) : (
          <UpdateOutlined />
        )}
      </ListItemAvatar>
      <ListItemText
        style={{ padding: '0px 6px' }}
        primary={
          <Grid container>
            <Grid item xs={7}>
              <NotificationUserName item={item} />
            </Grid>
            <Grid item xs={5} align="right">
              <Typography variant="caption">
                {getTimeInterval(item?.createdAt)}
              </Typography>
            </Grid>
          </Grid>
        }
        secondary={
          <Typography
            component="span"
            variant="subtitle2"
            className={classes.fullText}>
            {item?.rawData?.action === 'STALE_CAMPAIGNS'
              ? `${item?.rawData?.advertiser?.name} campaign: `
              : ''}
            <Link
              onClick={() => {
                navigateTo(item?.rawData?.campaignRequest?.id);
                setIsPulled(false);
              }}
              component="span"
              variant="subtitle2"
              color="primary">
              {item?.rawData?.campaignRequest?.name}
            </Link>
            &nbsp;has missed its start date.
          </Typography>
        }
      />
      <NotificationItemActions
        onActionHandler={onActionHandler}
        id={item?.id}
      />
    </ListItem>
  );
};

export default StaleCampaignNotificationItem;
