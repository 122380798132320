import PropTypes from 'prop-types';

import Autocomplete from '@audiencetown/owk-uitk/dist/components/Autocomplete';
import TextField from '@audiencetown/owk-uitk/dist/components/TextField';

function CampaignFilter({ campaignId, campaigns, onChange, disabled }) {
  return (
    <Autocomplete
      style={{ width: 200 }}
      id="demo-simple-select-required"
      size="small"
      options={campaigns}
      getOptionLabel={campaign => (campaign.name ? campaign.name : '')}
      getOptionSelected={(campaign, value) => campaign.value === value.value}
      onChange={onChange}
      disabled={disabled}
      value={campaignId}
      renderInput={params => (
        <TextField {...params} required label="Campaign" variant="outlined" />
      )}
    />
  );
}

CampaignFilter.defaultProps = {
  campaigns: [],
  disabled: true,
};
CampaignFilter.propTypes = {
  campaigns: PropTypes.array,
  disabled: PropTypes.bool,
};

export default CampaignFilter;
