import Grid from '@audiencetown/owk-uitk/dist/components/Grid';

import Status from '../components/Status';

const renderControl = (component, index) => {
  switch (component.name) {
    case 'Status':
      return <Status key={index} {...component.props} />;
    default:
      return <></>;
  }
};

export const customHeaderController = components => {
  return components.map((component, index) => (
    <Grid item key={index}>
      {renderControl(component, index)}
    </Grid>
  ));
};
