import IconButton from '@audiencetown/owk-uitk/dist/components/IconButton';
import Tooltip from '@audiencetown/owk-uitk/dist/components/Tooltip';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';

import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles(() => ({
  icon: {
    width: '2.5rem',
    height: '2.5rem',
  },
}));

const SmallIcon = props => {
  const classes = useStyle();
  return (
    <Tooltip title={props?.disabled ? '' : props?.title}>
      <Grid>
        <IconButton
          variant={props.variant ?? 'outlined'}
          {...props}
          title={undefined}
          className={classes.icon}>
          {props.children}
          <props.icon fontSize="small" />
        </IconButton>
      </Grid>
    </Tooltip>
  );
};

export default SmallIcon;
