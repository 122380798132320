import React, { useEffect, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import routes from '../routes';
import Layout from '../components/layout';
import { userContext } from '../userContext';

function SwitchRoutes({ isLoggedIn, removeItems }) {
  return (
    <>
      <Routes>
        {routes
          .filter(item => removeItems.includes(item.name))
          .map((prop, key) => {
            if (prop.layout === 'main') {
              return (
                <Route
                  path={prop.path}
                  exact={prop.isExact}
                  key={key.toString()}
                  element={<Layout Component={prop.component} />}
                />
              );
            }
            return null;
          })}
        <Route
          path="/"
          element={
            isLoggedIn ? <Navigate replace to="/reports/performance" /> : ' '
          }
        />
      </Routes>
    </>
  );
}
function Main() {
  const { user } = useContext(userContext);

  const removeItems = [
    'Dashboard',
    'Notifications',
    'coming soon',
    'Edit Profile',
    'reports',
    'Advertisers List',
    'Users',
    'Settings',
    'Pixel Management',
    'Financial Survey',
    'Type Management',
    'Profiling Survey',
    'Invoicing',
    'Requested Campaigns',
    'Agencies List',
    'Campaign Hub',
    'Not Found',
    'Creative Hub',
    'Create Campaign',
  ];

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `Bearer ${user.userData.token}`;
  }, [user.userData.token]);

  return <SwitchRoutes removeItems={removeItems} isLoggedIn={user.loggedIn} />;
}

SwitchRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  removeItems: PropTypes.arrayOf(PropTypes.any),
};

SwitchRoutes.defaultProps = {
  isLoggedIn: false,
  removeItems: [],
};

export default Main;
