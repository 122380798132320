import DisplayData from '../components/publishedHistory/displayData';

export const getTitle = (value, parentProp, data) => {
  switch (value) {
    case 'outcome':
      return 'GOAL';
    case 'geo':
      return (data[parentProp].hasOwnProperty('added') ||
        data[parentProp].hasOwnProperty('deleted')) &&
        Object.keys(
          data[parentProp]?.added
            ? data[parentProp]?.added?.geo
            : data[parentProp]?.deleted?.geo,
        ).length < 2
        ? ''
        : parentProp === 'secondaryAudience'
        ? 'GEO (SECONDARY AUDIENCE) '
        : 'GEO (PRIMARY AUDIENCE)  ';
    case 'movers':
      return parentProp === 'secondaryAudience'
        ? 'MOVERS (SECONDARY AUDIENCE)'
        : 'MOVERS (PRIMARY AUDIENCE)  ';
    case 'flights':
      return 'FLIGHTS ';
    case 'channels':
      return 'CHANNELS ';
    case 'budget':
      return 'BUDGET ';

    case 'pricing':
      return 'PRICING ';
    case 'endDate':
      return 'END DATE ';

    case 'startDate':
      return 'START DATE ';
    case 'creativeDiff':
      return 'ASSETS';
    case 'campaignName':
      return 'CAMPAIGN NAME';

    default:
      return <></>;
  }
};

export const displayOptions = [
  'channels',
  'flights',
  'outcome',
  'primaryAudience',
  'secondaryAudience',
  'budget',
  'pricing',
  'endDate',
  'startDate',
  'campaignName',
];

export const renderChild = (data, value, prop) => {
  switch (value) {
    case 'outcome':
      return Object.keys(data[value])?.map(el => {
        return el === 'deleted' || el === 'added' ? (
          <DisplayData
            data={
              el === 'deleted' || el === 'added'
                ? data[value][el]?.values
                : data[value][el]?.values
            }
            prop={
              el === 'deleted' || el === 'added'
                ? el
                : Object.keys(data[value][0])
            }
          />
        ) : (
          Object.keys(data[value][el])
            ?.filter(element => data[value][el][element]?.length)
            ?.map((el, index) => (
              <>
                {index === 0 ? '' : ','}
                <DisplayData data={data[value]?.values} prop={el} />
              </>
            ))
        );
      });

    case 'movers':
      return (
        <DisplayData
          data={data}
          prop={value}
          parentProp={prop}
          identifier={'movers'}
        />
      );

    case 'geo':
      return (
        <DisplayData
          data={data}
          prop={value}
          parentProp={prop}
          identifier={'geo'}
        />
      );

    case 'flights':
      return Object.keys(data[value])
        ?.sort((a, b) => (a > b ? -1 : b > a ? 1 : 0))
        .map(el => {
          return (
            <DisplayData data={data[value]} prop={el} identifier={'flights'} />
          );
        });
    case 'channels':
      return Object.keys(data[value])
        ?.filter(el => data[value][el]?.length)
        .map((el, index) => (
          <>
            {index === 0 ? '' : ','}
            <DisplayData data={data[value]} prop={el} identifier={'channels'} />
          </>
        ));
    case 'budget':
      return Object.keys(data[value]).map(el => (
        <DisplayData data={data[value]} prop={el} identifier={'budget'} />
      ));
    case 'endDate':
      return Object.keys(data[value]).map(el => (
        <DisplayData data={data[value]} prop={el} identifier={'endDate'} />
      ));

    case 'startDate':
      return Object.keys(data[value]).map(el => (
        <DisplayData data={data[value]} prop={el} identifier={'startDate'} />
      ));

    case 'campaignName':
      return Object.keys(data[value]).map(el => (
        <DisplayData data={data[value]} prop={el} identifier={'campaignName'} />
      ));

    case 'pricing':
      return Object.keys(data[value])
        ?.filter(el => data[value][el]?.length)
        ?.map((el, index) => (
          <>
            {index === 0 ? '' : ','}
            <DisplayData data={data[value]} prop={el} identifier={'pricing'} />
          </>
        ));

    default:
      return <></>;
  }
};
