import React from 'react'

import List from '@audiencetown/owk-uitk/dist/components/List';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Skeleton from '@audiencetown/owk-uitk/dist/components/Skeleton';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  smallAvatar: {
    width: '1.5rem',
    height: '1.5rem',
  },
  itemAvatar: {
    minWidth: '2rem',
  },
}));

const HistoryListSkeleton = () => {
    const classes = useStyles()
    return (
        <List>
          {[...Array(10)].map((_, index) => (
            <ListItem alignItems="flex-start" key={`ListItemKey_${index}`}>
              <ListItemAvatar>
                <Skeleton
                  variant="circle"
                  className={`${classes.skeleton} ${classes.smallAvatar}`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Skeleton variant="text" className={classes.skeleton} />
                    </Grid>
                    <Grid item xs={5}>
                      <Skeleton variant="text" className={classes.skeleton} />
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Skeleton variant="text" className={classes.skeleton} />
                }
              />
            </ListItem>
          ))}
        </List>
    )
}

export default HistoryListSkeleton;
