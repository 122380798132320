import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  text: {
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
}));

const Geo = ({ data, prop, parentProp }) => {
  const classes = useStyles();

  return parentProp
    ? Object.keys(data[prop])?.map(element =>
        Array.isArray(data[prop][element]) ? (
          data[prop][element]?.map((el, idx) => (
            <Grid item>
              <Typography
                variant="subtitle2"
                color={parentProp === 'added' ? 'primary' : 'secondary'}
                className={`${classes.text} ${
                  parentProp === 'deleted' ? classes.lineThrough : ''
                }`}>
                {`${el.name}${
                  idx === data[prop][element]?.length - 1 ? '' : ','
                } `}
              </Typography>
            </Grid>
          ))
        ) : element === 'city' || element === 'zipcode' ? (
          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.text}>
              {` ${data[prop][element]}`}
            </Typography>
          </Grid>
        ) : (
          ''
        ),
      )
    : Object.keys(data[prop])?.map(element =>
        Object.keys(data[prop][element]).map(ele =>
          Array.isArray(data[prop][element][ele]) ? (
            data[prop][element][ele].map((el, idx) => (
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color={ele === 'added' ? 'primary' : 'secondary'}
                  className={`${classes.text} ${
                    ele === 'deleted' ? classes.lineThrough : ''
                  }`}>
                  {`${el.name}${
                    idx === data[prop][element][ele]?.length - 1 ? '' : ','
                  } `}
                </Typography>
              </Grid>
            ))
          ) : element === 'city' || element === 'zipcode' ? (
            data[prop][element][ele].hasOwnProperty('oldValue') ? (
              <>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    className={`${classes.text} ${classes.lineThrough}`}>
                    {`${data[prop][element][ele].oldValue}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={classes.text}>
                    {` ${data[prop][element][ele].newValue}`}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color={ele === 'added' ? 'primary' : 'secondary'}
                  className={`${classes.text} ${
                    ele === 'deleted' ? classes.lineThrough : ''
                  }`}>
                  {` ${data[prop][element][ele]} `}
                </Typography>
              </Grid>
            )
          ) : (
            <></>
          ),
        ),
      );
};

export default Geo;
