const customSort = (a, b, direction) =>
  direction === 'asc' && a
    ? b
      ? a.toString().localeCompare(b, undefined, { numeric: true })
      : -1
    : a
    ? b.toString().localeCompare(a, undefined, { numeric: true })
    : 1;

export default customSort;
