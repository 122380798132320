import { noDataText } from '../../../helpers/constants.helper';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';

const EmptyHistoryList = () => {
  return (
    <div>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="h6">{noDataText}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmptyHistoryList;
