// TODO: move all format functionality to this file

import moment from 'moment';

const FORMATTER_KEYS = {
  PERCENTAGE: 'percentage',
  CURRENCY: 'currency',
  DEFAULT: 'default',
  CURRENCY_SHORT: 'currency_short',
  PERCENTAGE_SHORT: 'percentage_short',
};

const FORMATTERS = {
  percentage: { digits: 2, suffix: '%' },
  currency: { digits: 2, prefix: '$' },
  default: { digits: 0 },
  currency_short: { digits: 0, prefix: '$' },
  percentage_short: { digits: 0, suffix: '%' },
};

const format = (value, key) => {
  if (key in FORMATTERS && isNaN(value)) return 'N/A';
  if (value === undefined) return undefined;
  if (value === null) return 'N/A';

  if (!isNaN(value)) {
    const digits =
      typeof FORMATTERS[key]?.digits === 'number' ? FORMATTERS[key].digits : 2;
    return `${FORMATTERS[key]?.prefix || ''}${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: digits,
    }).format(
      parseFloat(isNaN(value) || !isFinite(value) ? 0 : value).toFixed(digits),
    )}${FORMATTERS[key]?.suffix || ''}`;
  } else if (Date.parse(value)) {
    moment(value).format('YYYY-MM-DD');
  }
  return value;
};

const FormatHelper = {
  FORMATTERS: FORMATTER_KEYS,
  format,
};

export default FormatHelper;
