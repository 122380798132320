import { parseDate } from './constants.helper';

export const dateRangeOverlap = dateRanges => {
  if (dateRanges?.length > 1) {
    const overlappedItemsInDates = dateRanges
      ?.sort(
        (previous, current) =>
          new Date(previous?.startDate) - new Date(current?.startDate),
      )
      .filter((current, index, arr) =>
        index > 0
          ? parseDate(arr[index - 1]?.endDate) >= parseDate(current?.startDate)
          : false,
      );

    return !!overlappedItemsInDates.length;
  }
  return false;
};
