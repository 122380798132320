import MoversTitle from './moversTitle';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  text: {
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
}));

const Movers = ({ data, prop, parentProp }) => {
  const classes = useStyles();

  return parentProp
    ? Object.keys(data[prop])?.map(element => (
        <Grid container>
          {data[prop][element].length || element === 'crmEnabled' ? (
            <MoversTitle element={element} />
          ) : (
            <></>
          )}
          {Array.isArray(data[prop][element]) ? (
            data[prop][element]?.map((el, index) => (
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color={parentProp === 'added' ? 'primary' : 'secondary'}
                  className={`${classes.text} ${
                    parentProp === 'deleted' ? classes.lineThrough : ''
                  }`}>
                  {element === 'slider'
                    ? index === data[prop][element].length - 1
                      ? `${el}M  `
                      : `${el}M  TO `
                    : `${el}${
                        index === data[prop][element].length - 1 ? '' : ','
                      } `}
                </Typography>
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography
                variant="subtitle2"
                color={parentProp === 'added' ? 'primary' : 'secondary'}
                className={`${classes.text} ${
                  parentProp === 'deleted' ? classes.lineThrough : ''
                }`}>
                {` ${
                  data[prop][element] === true
                    ? 'Enabled'
                    : data[prop][element] === false
                    ? 'Disabled'
                    : data[prop][element]
                }`}
              </Typography>
            </Grid>
          )}
        </Grid>
      ))
    : Object.keys(data[prop]).map(element => (
        <Grid container>
          {Object.keys(data[prop][element]).length ||
          element === 'crmEnabled' ? (
            <MoversTitle element={element} />
          ) : (
            <></>
          )}
          {Object.keys(data[prop][element]).map(ele =>
            Array.isArray(data[prop][element][ele]) ? (
              data[prop][element][ele].map((el, index) => (
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color={ele === 'added' ? 'primary' : 'secondary'}
                    className={`${classes.text} ${
                      ele === 'deleted' ? classes.lineThrough : ''
                    }`}>
                    {element === 'slider'
                      ? index === data[prop][element][ele].length - 1
                        ? `${el}M  `
                        : `${el}M  TO `
                      : `${el}${
                          index === data[prop][element][ele].length - 1
                            ? ''
                            : ','
                        } `}
                  </Typography>
                </Grid>
              ))
            ) : data[prop][element][ele].hasOwnProperty('oldValue') ? (
              <>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    className={`${classes.text} ${classes.lineThrough}`}>
                    {`${
                      data[prop][element][ele].oldValue === true
                        ? 'Enabled'
                        : data[prop][element][ele].oldValue === false
                        ? 'Disabled'
                        : data[prop][element][ele].oldValue
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={`${classes.text}`}>
                    {` ${
                      data[prop][element][ele].newValue === true
                        ? 'Enabled'
                        : data[prop][element][ele].newValue === false
                        ? 'Disabled'
                        : data[prop][element][ele].newValue
                    }`}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color={ele === 'added' ? 'primary' : 'secondary'}
                  className={`${classes.text} ${
                    ele === 'deleted' ? classes.lineThrough : ''
                  }`}>
                  {` ${data[prop][element][ele]} `}
                </Typography>
              </Grid>
            ),
          )}
        </Grid>
      ));
};

export default Movers;
