import { useContext, useState } from 'react';

import { HistoryContext } from '../../historyContext';
import HistoryDateRangePicker from './components/HistoryDateRangePicker';
import HistoryListSkeleton from './components/HistoryListSkeleton';
import CampaignHistory from './components/CampaignHistory';
import EmptyHistoryList from './components/EmptyHistoryList';
import CampaignPublishedHistory from './components/campaignPublishedHistory';
import InvoiceHistory from './components/invoiceHistory';
import Drawer from '@audiencetown/owk-uitk/dist/components/Drawer';
import Box from '@audiencetown/owk-uitk/dist/components/Box';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import List from '@audiencetown/owk-uitk/dist/components/List';
import Divider from '@audiencetown/owk-uitk/dist/components/Divider';
import { HistoryOutlined } from '@audiencetown/owk-uitk/dist/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  list: {
    maxWidth: '26rem',
    minWidth: '26rem',
    overflowX: 'hidden',
  },
  divider: {
    backgroundColor: theme.palette.secondary.gray,
  },
  icon: {
    verticalAlign: 'middle',
  },
}));

const HistoryDrawer = () => {
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();
  const handleLoadingChange = bool => setIsLoading(bool);

  const {
    fetchId,
    drawerTitle,
    showDateFilter,
    historyListComponentName,
    toggleHistoryDrawer,
    historyDateFilter,
    openHistoryDrawer,
  } = useContext(HistoryContext);

  const HistoryListComponents = name => {
    switch (name) {
      case 'campaign':
        return (
          <CampaignHistory
            datesFilters={historyDateFilter}
            id={fetchId}
            handleLoadingChange={handleLoadingChange}
          />
        );
      case 'BillingHistory':
        return (
          <InvoiceHistory
            datesFilters={historyDateFilter}
            id={fetchId}
            handleLoadingChange={handleLoadingChange}
          />
        );
      case 'campaignPublished':
        return (
          <CampaignPublishedHistory
            datesFilters={historyDateFilter}
            id={fetchId}
            handleLoadingChange={handleLoadingChange}
            isLoading={isLoading}
          />
        );

      default:
        return <EmptyHistoryList />;
    }
  };

  const list = () => (
    <Box
      className={classes.list}
      role="presentation"
      onKeyDown={() => toggleHistoryDrawer()}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box
            p={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <HistoryOutlined className={classes.icon} />
              </Grid>
              <Grid item>
                {showDateFilter && (
                  <Typography variant="h6">{drawerTitle}</Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box p={2}>
            <HistoryDateRangePicker disabled={isLoading} />
          </Box>
        </Grid>
      </Grid>
      <Divider variant="middle" className={classes.divider} />
      {isLoading && <HistoryListSkeleton />}
      <List>{HistoryListComponents(historyListComponentName)}</List>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="right"
        color="secondary"
        open={openHistoryDrawer}
        onClose={() => toggleHistoryDrawer()}>
        {list()}
      </Drawer>
    </>
  );
};

export default HistoryDrawer;
