import { createContext, useState, useMemo } from 'react';
import moment from 'moment';

export const HistoryContext = createContext();

export const HistoryProvider = ({ children }) => {
  const [drawerTitle, setDrawerTitle] = useState('Activities History');
  const [showDateFilter, setShowDateFilter] = useState(true);
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);
  const [historyListComponentName, setHistoryListComponentName] = useState('');
  const [fetchId, setFetchId] = useState();
  const [historyDateFilter, setHistoryDateFilter] = useState({
    timeline: 7,
    customRange: [
      moment().subtract(7, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ],
  });

  const toggleHistoryDrawer = () => setOpenHistoryDrawer(!openHistoryDrawer);
  const componentToRender = (name, id) => {
    setHistoryListComponentName(name);
    setFetchId(id);
  };

  const changeHistoryDrawerTitle = title => setDrawerTitle(title);
  const changeViewDateFilter = bool => setShowDateFilter(bool);

  const value = useMemo(
    () => ({
      fetchId,
      drawerTitle,
      historyDateFilter,
      openHistoryDrawer,
      historyListComponentName,
      showDateFilter,
      toggleHistoryDrawer,
      setHistoryDateFilter,
      componentToRender,
      changeHistoryDrawerTitle,
      changeViewDateFilter,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      drawerTitle,
      historyDateFilter,
      openHistoryDrawer,
      historyListComponentName,
      showDateFilter,
      fetchId,
    ],
  );

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
