import axios from 'axios';

export const MediaMathPublishWrapper = {
  publishCampaign: campaign =>
    axios
      .post('/integration/mediamath/campaign/', campaign)
      .then(res => res.data),
  publishCampaignFlights: (campaign_source_id, flights) =>
    axios
      .post(
        `/integration/mediamath/campaign/flights/${campaign_source_id}`,
        flights,
      )
      .then(res => res.data),
  publishCreatives: creative =>
    axios
      .post('/integration/mediamath/campaign/creatives', creative)
      .then(res => res.data),
};

export const createCampaign = data =>
  axios.post('/campaign/', data).then(res => res.data);

export const createCampaignIntegration = (id, data) =>
  axios.post(`/campaign/${id}/integration/`, data).then(res => res.data);
