import React, { useContext, useEffect, useState } from 'react';

import { BreadcrumbsContext } from '../breadcrumbsContext';
import { ActionPanelContext } from '../actionPanelContext';
import { CustomHeaderContext } from '../CustomHeaderContext';

import { ActionPanelControlsReducer } from './action panel/controls/helpers/actionPanelControlsReducer';
import { customHeaderController } from './custom header/helpers/customHeaderController';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import LinkRouter from '@audiencetown/owk-uitk/dist/components/LinkRouter';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import Breadcrumbs from '@audiencetown/owk-uitk/dist/components/Breadcrumbs';
import Tooltip from '@audiencetown/owk-uitk/dist/components/Tooltip';

const Layout = ({ Component }) => {
  const { breadcrumbsItems } = useContext(BreadcrumbsContext);
  const { actionPanelComponents, disabled } = useContext(ActionPanelContext);
  const { customHeaderComponents } = useContext(CustomHeaderContext);

  const [renderedActionPanelComponent, setRenderedActionPanelComponent] =
    useState([]);
  const [renderedCustomHeaderComponent, setRenderedCustomHeaderComponent] =
    useState([]);

  useEffect(() => {
    setRenderedActionPanelComponent(
      ActionPanelControlsReducer(actionPanelComponents, disabled),
    );
  }, [actionPanelComponents, disabled]);

  useEffect(() => {
    setRenderedCustomHeaderComponent(
      customHeaderController(customHeaderComponents),
    );
  }, [customHeaderComponents]);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        wrap="nowrap"
        style={{ marginBottom: '2rem' }}>
        <Grid container item style={{ width: 'fit-content' }} spacing={3}>
        <Grid item>
          <Breadcrumbs maxItems={2} itemsBeforeCollapse={0}>
            {breadcrumbsItems.map((breadcrumbItem, index) =>
              index === breadcrumbsItems?.length - 1 ? (
                <Tooltip title={breadcrumbItem.text} key={index}>
                  <Typography
                    style={{
                      maxWidth: '40rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    noWrap={true}
                    color="primary"
                    variant="h4"
                  >
                    {breadcrumbItem.text}
                  </Typography>
                </Tooltip>
              ) : (
                <LinkRouter
                  color="textPrimary"
                  to={breadcrumbItem.pathname}
                  key={index}
                  onClick={breadcrumbItem.onClick}
                  {...(breadcrumbItem.props ?? {})}
                >
                  <Typography key={index} variant="h4">
                    {breadcrumbItem.text}
                  </Typography>
                </LinkRouter>
              ),
            )}
          </Breadcrumbs>
        </Grid>
        {
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            style={{ width: 'fit-content' }}
          >
            {renderedCustomHeaderComponent}
          </Grid>
        }
      </Grid>
      {
        <Grid
          container
          item
          spacing={1}
          style={{ width: 'fit-content' }}
          alignItems="center"
        >
          {renderedActionPanelComponent}
        </Grid>
      }
    </Grid>
      <Component />
    </>
  );
};

export default Layout;
