import React, { useState, createContext } from 'react';

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [updateNotificationContext, setUpdateNotificationContext] =
    useState(false);

  const value = {
    updateNotificationContext,
    setUpdateNotificationContext,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
