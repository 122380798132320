import { createContext, useState } from 'react';

export const ActionPanelContext = createContext();

export const ActionPanelProvider = ({ children }) => {
  const [actionPanelComponents, setActionPanelComponents] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const value = {
    actionPanelComponents,
    setActionPanelComponents,
    disabled,
    setDisabled,
  };
  return (
    <ActionPanelContext.Provider value={value}>
      {children}
    </ActionPanelContext.Provider>
  );
};
