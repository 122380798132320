import { createContext, useState } from 'react';

export const CustomHeaderContext = createContext();

export const CustomHeaderProvider = ({ children }) => {
  const [customHeaderComponents, setCustomHeaderComponents] = useState([]);
  const value = {
    customHeaderComponents,
    setCustomHeaderComponents,
  };

  return (
    <CustomHeaderContext.Provider value={value}>
      {children}
    </CustomHeaderContext.Provider>
  );
};
