import { Edit, Delete } from '@audiencetown/owk-uitk/dist/icons';

export const tableActions = [
  { title: 'Edit', name: 'edit', icon: <Edit color="primary" /> },
  {
    title: 'Delete',
    name: 'delete',
    icon: <Delete color="primary" />,
  },
];

export const editTableAction = [
  { title: 'Edit', name: 'edit', icon: <Edit color="primary" /> },
];
