import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import List from '@audiencetown/owk-uitk/dist/components/List';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Divider from '@audiencetown/owk-uitk/dist/components/Divider';
import { FiberManualRecord } from '@audiencetown/owk-uitk/dist/icons';
import Skeleton from '@audiencetown/owk-uitk/dist/components/Skeleton';
import Link from '@audiencetown/owk-uitk/dist/components/Link';
import Box from '@audiencetown/owk-uitk/dist/components/Box';

import CampaignCreatedNotificationItem from './components/campaignCreatedNotificationItem';
import CampaignRequestNotification from './components/campaignRequestNotificationItem';
import ClientRequestNotificationItem from './components/clientRequestNotificationItem';
import NewTaskNotificationItem from './components/newTaskNotificationItem';
import StaleCampaignNotificationItem from './components/staleCampaignNotificationItem';
import PixelNotificationItem from './components/pixelNotificationItem';
import AgingTaskNotificationItem from './components/agingTaskNotificationItem';

import {
  deleteNotification,
  unreadNotification,
} from './services/notifications.service';
import CampaignSubscription from './components/campaignSubscriptionNotificationItem';

const useStyles = makeStyles(theme => ({
  smallAvatar: {
    width: '1.5rem',
    height: '1.5rem',
  },
  itemAvatar: {
    minWidth: '2rem',
  },
  listItem: {
    paddingLeft: '0rem',
  },
  incompleteText: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  unreadIcon: {
    width: '0.625rem',
    marginRight: '0rem',
    padding: '0rem',
    marginLeft: '0.5rem',
    marginTop: '1rem',
  },
  linkMark: {
    marginTop: '0.56rem',
  },
  skeleton: {
    backgroundColor: theme.palette.secondary.gray,
  },
  box: {
    maxWidth: '22rem',
    minWidth: '22rem',
    overflowX: 'hidden',
  },
  divider: {
    backgroundColor: theme.palette.secondary.gray,
  },
}));
const Notifications = ({
  setIsPulled,
  notifications,
  editNotificationsHandler,
  loading,
  readNotifications,
  loadMoreHandler,
  loadMoreLoading,
  noMoreNotifications,
}) => {
  const classes = useStyles();

  const handleRead = async () => {
    await readNotifications();
    const newNotifications = notifications.map(item => {
      if (!item.isRead) {
        return { ...item, isRead: true };
      }
      return item;
    });
    editNotificationsHandler(newNotifications);
  };
  const onActionHandler = async (action, id) => {
    if (action === 'delete') {
      await deleteNotification(id);
      const filteredItems = notifications.filter(item => item.id !== id);
      editNotificationsHandler(filteredItems);
    } else if (action === 'unread') {
      await unreadNotification(id);
      const newNotifications = notifications.map(item => {
        if (item.id === id) {
          return { ...item, isRead: false };
        }
        return item;
      });
      editNotificationsHandler(newNotifications);
    }
  };
  const renderChild = notification => {
    switch (notification?.rawData?.action) {
      case 'ADVERTISER_PIXEL_NOT_VERIFIED':
        return (
          <PixelNotificationItem
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
          />
        );
      case 'ADVERTISER_PIXEL_VERIFIED':
        return (
          <PixelNotificationItem
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
          />
        );
      case 'CAMPAIGN_REQUEST_TASK_CREATED':
        return (
          <NewTaskNotificationItem
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
            text={'New task assigned to you'}
          />
        );
      case 'CAMPAIGN_REQUEST_CREATED':
        return (
          <CampaignCreatedNotificationItem
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
          />
        );

      case 'CAMPAIGN_SUBSCRIPTION':
        return (
          <CampaignSubscription
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
          />
        );
      case 'CLIENT_SERVICE_REQUEST_CREATED':
        return (
          <ClientRequestNotificationItem
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
          />
        );

      case 'CAMPAIGN_REQUEST_SUBMITTED':
        return (
          <CampaignRequestNotification
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
            text={'Campaign request submitted'}
          />
        );
      case 'CAMPAIGN_REQUEST_CANCELLED':
        return (
          <CampaignRequestNotification
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
            text={'Campaign request cancelled'}
          />
        );
      case 'CAMPAIGN_REQUEST_APPROVED':
        return (
          <CampaignRequestNotification
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
            text={'Campaign request approved'}
          />
        );
      case 'CAMPAIGN_REQUEST_PUBLISHED':
        return (
          <CampaignRequestNotification
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
            text={'Campaign request published'}
          />
        );
      case 'CAMPAIGN_ORDER_UPDATED':
        return (
          <CampaignRequestNotification
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
            text={'Campaign order updated'}
          />
        );
      case 'CAMPAIGN_REQUEST_RESUMED':
        return (
          <CampaignRequestNotification
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
            text={'Campaign request resumed'}
          />
        );
      case 'STALE_CAMPAIGNS':
        return (
          <StaleCampaignNotificationItem
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
          />
        );
         case 'AGING_TASKS':
        return (
          <AgingTaskNotificationItem
            setIsPulled={setIsPulled}
            onActionHandler={onActionHandler}
            item={notification}
          />
        );

      default:
        return <>{notification.rawData.action}</>;
    }
  };
  return (
    <Box className={classes.box}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        px={3}
        py={1}>
        <Typography variant="h6">Notifications</Typography>
        <Link color="primary" variant="caption" onClick={handleRead}>
          Mark All As Read
        </Link>
      </Box>
      {loading ? (
        <List>
          {[...Array(10)].map((element, i) => (
            <ListItem alignItems="flex-start" key={`ListItemKey_${i}`}>
              <ListItemAvatar>
                <Skeleton
                  variant="circle"
                  className={`${classes.skeleton} ${classes.smallAvatar}`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Skeleton variant="text" className={classes.skeleton} />
                    </Grid>
                    <Grid item xs={5}>
                      <Skeleton variant="text" className={classes.skeleton} />
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Skeleton variant="text" className={classes.skeleton} />
                }
              />
            </ListItem>
          ))}
        </List>
      ) : notifications.length ? (
        <>
          {notifications.map((notification, i) =>
            notification?.rawData?.action ? (
              <React.Fragment key={`Notification_Item_${i}`}>
                <Divider variant="middle" className={classes.divider} />
                <Grid container>
                  <Grid item align="left" xs={1}>
                    {!notification.isRead && (
                      <FiberManualRecord
                        color="primary"
                        className={classes.unreadIcon}
                      />
                    )}
                  </Grid>
                  <Grid item xs={11}>
                    {renderChild(notification)}
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment key={`Notification_Item_${i}`} />
            ),
          )}
          {loadMoreLoading ? (
            <>
              <List>
                {[...Array(10)].map((element, i) => (
                  <ListItem alignItems="flex-start" key={`ListItemKey_${i}`}>
                    <ListItemAvatar>
                      <Skeleton
                        variant="circle"
                        className={`${classes.skeleton} ${classes.smallAvatar}`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <Skeleton
                              variant="text"
                              className={classes.skeleton}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Skeleton
                              variant="text"
                              className={classes.skeleton}
                            />
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <Skeleton variant="text" className={classes.skeleton} />
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </>
          ) : !noMoreNotifications ? (
            <Grid container justifyContent="center">
              <Link color="primary" variant="caption" onClick={loadMoreHandler}>
                Load More
              </Link>
            </Grid>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Typography align="center">
            You Don't Have Any Notifications.
          </Typography>
        </>
      )}
    </Box>
  );
};

export default Notifications;
