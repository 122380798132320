import PropTypes from 'prop-types';

import InputAdornment from '@audiencetown/owk-uitk/dist/components/InputAdornment';
import TextField from '@audiencetown/owk-uitk/dist/components/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles(() => ({
  search: {
    width: 200,
  },
}));

const SearchTable = ({ handleSearch, disabled, searchParam }) => {
  const classes = useStyle();
  return (
    <TextField
      className={classes.search}
      id="input-with-icon-textfield5"
      variant="outlined"
      size="small"
      placeholder="Search"
      onChange={handleSearch}
      disabled={disabled}
      value={searchParam}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchTable.defaultProps = {
  handleSearch: () => {},
  disabled: true,
};

SearchTable.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SearchTable;
