import config from '../config';

export const validateFiles = files => {
  const rejected = [];
  const accepted = [];
  files?.forEach(item => {
    item.isSelected = true;
    if (
      (item.type.startsWith('image') &&
        item.size > config.imageFileSizeLimit) ||
      ((item.type.startsWith('video') || item.type.startsWith('audio')) &&
        item.size > config.othersFileSizeLimit)
    ) {
      rejected.push(item);
    } else accepted.push(item);
  });

  return { accepted, rejected };
};
