import axios from 'axios';

export const CampaignRequestService = {
  get: id => axios.get(`campaign-request/${id}`).then(res => res.data),
  update: (id, data) =>
    axios.put(`campaign-request/${id}`, data).then(res => res.data),
  patch: (id, data) =>
    axios.patch(`campaign-request/${id}`, data).then(res => res.data),
};

export const CampaignRequestTaskService = {
  get: id =>
    axios.get(`campaign-request/${id}/tasks/`).then(res => res.data?.rows),
  create: (id, data) =>
    axios.post(`campaign-request/${id}/tasks/`, data).then(res => res.data),
  update: (id, updatedId, data) =>
    axios
      .put(`campaign-request/${id}/tasks/${updatedId}`, data)
      .then(res => res.data),
  delete: (id, taskId) =>
    axios.delete(`campaign-request/${id}/tasks/${taskId}`),
};

export const CampaignRequestClientService = {
  get: id =>
    axios.get(`campaign-request/${id}/service/`).then(res => res.data?.rows),
  create: (id, data) =>
    axios.post(`campaign-request/${id}/service/`, data).then(res => res.data),
  update: (id, updatedId, data) =>
    axios
      .put(`campaign-request/${id}/service/${updatedId}`, data)
      .then(res => res.data),
  delete: (id, serviceId) =>
    axios.delete(`campaign-request/${id}/service/${serviceId}`),
};

export const getCategoryTasks = () => [
  { name: 'Invoicing' },
  { name: 'Campaign Specifications' },
  { name: 'Publishing and Reporting' },
];

export const CampaignRequestCreativeService = {
  put: async (id, data) =>
    axios.put(`campaign-request/${id}/creative/`, data).then(res => res.data),
  get: async id =>
    axios.get(`campaign-request/${id}/creative/`).then(res => res.data),
};

export const CampaignRequestAuditService = {
  get: (id, startDate, endDate) =>
    axios
      .get(`campaign-request/${id}/audit/`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then(res => res.data),
};
