import { useState, createContext } from 'react';

import moment from 'moment';

export const PerformanceDataGlobalFilterContext = createContext();

export const PerformanceDataGlobalFilterProvider = ({ children }) => {
  const [dateFilter, setDateFilter] = useState({
    timeline: 7,
    customRange: [
      moment().subtract(7, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ],
  });

  const value = {
    dateFilter,
    setDateFilter,
  };

  return (
    <PerformanceDataGlobalFilterContext.Provider value={value}>
      {children}
    </PerformanceDataGlobalFilterContext.Provider>
  );
};
