import moment from 'moment';

const MappingFormatterDigits = {
  cpm: { prefix: '$' },
  ctr: { suffix: '%' },
  cpa: { prefix: '$' },
  cpc: { prefix: '$' },
  ctc: { suffix: '%' },
  pacing: { suffix: '%' },
  budget: { prefix: '$' },
  impressions: { digits: 0 },
  ordered_impressions: { digits: 0 },
  clicks: { digits: 0 },
  total_conversions: { digits: 0 },
  total_spend: { prefix: '$' },
  video_start: { digits: 0 },
  video_first_quartile: { digits: 0 },
  video_midpoint: { digits: 0 },
  video_third_quartile: { digits: 0 },
  video_complete: { digits: 0 },
  post_click_conversions: { digits: 0 },
  post_view_conversions: { digits: 0 },
  rate: { digits: 3 },
  quantity: { digits: 2 },
  uniques: { digits: 0 },
  advertisersCount: { digits: 0 },
  campaignsCount: { digits: 0 },
  totalConversions: { digits: 0 },
  campaign: { digits: 0 },
  total: { digits: 3 },
  default: { digits: 2 },
  percentage: { digits: 2, suffix: '%' },
};

export const defaultFormatter = (num, option, country) =>
  new Intl.NumberFormat(country || 'en-US', option || {}).format(num || 0);

export const mappingFormatter = (value, key, map) => {
  if (value === undefined) return undefined;
  if (key in MappingFormatterDigits && isNaN(value)) return 'N/A';
  if (value === null) return 'N/A';

  if (typeof value === 'number') {
    const digits =
      typeof MappingFormatterDigits[key]?.digits === 'number'
        ? MappingFormatterDigits[key].digits
        : 2;

    return `${map[key]?.prefix || ''}${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: digits,
    }).format(
      parseFloat(
        Number.isNaN(value) || !Number.isFinite(value) ? 0 : value,
      ).toFixed(digits),
    )}${map[key]?.suffix || ''}`;
  } else if (Date.parse(value) && key.toLowerCase().includes('date')) {
    return moment(value).format(map[key] || 'YYYY-MM-DD');
  }
  return value;
};

const formatNumber = (
  value,
  key = 'default',
  formatMap = null,
  option = {},
  country = 'en-US',
) => {
  return Object.keys(option).length > 0
    ? value < 10000000
      ? mappingFormatter(value, key, formatMap)
      : {
          value: defaultFormatter(value, option, country),
          toolTipValue: mappingFormatter(value, key, formatMap),
        }
    : mappingFormatter(value, key, formatMap);
};

export default formatNumber;
