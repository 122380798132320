import { useEffect, useState } from 'react';

import { formatFullDateTime } from '../../../helpers';
import { SortHelper } from '../helpers/sortHelper';
import { HistoryService } from '../../../services';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import ProfileImage from '@audiencetown/owk-uitk/dist/assets/profile.svg';

const InvoiceHistory = ({ id, datesFilters, handleLoadingChange }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHistory = async () => {
      setData([]);
      handleLoadingChange(true);
      setData(
        SortHelper.sortByDate(
          await HistoryService.getAuditHistoryByInvoiceId(
            id,
            datesFilters[0],
            datesFilters[1],
          ),
          await HistoryService.getBillingHistoryByInvoiceId(
            id,
            datesFilters[0],
            datesFilters[1],
          ),
        ),
      );
      handleLoadingChange(false);
    };
    fetchHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, datesFilters]);
  return (
    <>
      {data &&
        data?.map(action => (
          <>
            <ListItem
              alignItems="center"
              justifyContent="center"
              key={`ListItemKey_${action?.id}`}>
              <ListItemAvatar>
                <img
                  style={{ width: '2.5rem', height: '2.5rem' }}
                  src={ProfileImage}
                  alt="user profile"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid container spacing={9}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">
                        {action?.metadata?.user?.name ??
                          action?.metadata?.updatedBy?.name ??
                          'SYSTEM'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">
                        {formatFullDateTime(action?.createdAt)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Typography variant="subtitle2">
                    {action?.action ?? action?.status}
                  </Typography>
                }
              />
            </ListItem>
          </>
        ))}
    </>
  );
};

export default InvoiceHistory;
