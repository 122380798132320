import axios from 'axios';

export const CreativeService = {
  upload: async (file, advertiserId, agencyId, advertiserName, agencyName) => {
    const formData = new FormData();

    if (file.newName) formData.append('file', file, file.newName);
    else formData.append('file', file);

    const config = {
      headers: {
        'Content-Type': `multipart/form-data`,
        data: JSON.stringify({
          advertiserId,
          agencyId,
          name: file.newName ? file.newName : file.name,
          metadata: {
            advertiserName,
            agencyName,
          },
          type: file.type.split('/')[0],
          size: file.size,
          extension: file.type.split('/')[1],
          duration: file.duration,
          dimension: { width: file.width, height: file.height },
          landingPage: file.landingPage,
          clickThroughUrl: file.clickThroughUrl,
        }),
      },
    };

    return axios.post('creative/', formData, config).then(res => res.data);
  },
  get: async id => axios.get(`creative/${id}`).then(res => res.data),
  list: async () => axios.get('creative/').then(res => res.data),
  info: async id => axios.get(`creative/${id}/info`).then(res => res.data),
  patch: async (id, data) =>
    axios.patch(`creative/${id}`, data).then(res => res.data),
};

export const updateCreativeInMM = async (id, data) =>
  axios
    .put(`integration/mediamath/campaign/creatives/${id}`, data)
    .then(res => res.data);
