import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import axios from 'axios';
import Theme from '@audiencetown/owk-uitk/dist/components/Theme';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';

import AppContainer from './layouts';
import config from './config';

axios.defaults.baseURL = config.apiBaseUrl;

const App = () => (
  <Grid container wrap="nowrap">
    <BrowserRouter>
      <Theme>
        <AppContainer />
      </Theme>
    </BrowserRouter>
  </Grid>
);

export default App;
