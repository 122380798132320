import { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { HistoryContext } from '../../../historyContext';

import MenuItem from '@audiencetown/owk-uitk/dist/components/MenuItem';
import InputAdornment from '@audiencetown/owk-uitk/dist/components/InputAdornment';
import TextField from '@audiencetown/owk-uitk/dist/components/TextField';
import { CalendarTodayOutlined } from '@audiencetown/owk-uitk/dist/icons';

function HistoryDateRangePicker({ disabled }) {
  const { historyDateFilter, setHistoryDateFilter } =
    useContext(HistoryContext);

  const handleTimeline = e => {
    const date = e.target.value;
    if (date === 'all') {
      setHistoryDateFilter({
        timeline: 'all',
        customRange: [],
      });
    }
    if (date !== 'all')
      setHistoryDateFilter({
        timeline: date,
        customRange: [
          moment().subtract(date, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
      });
  };

  return (
    <>
      <TextField
        size="small"
        select
        onChange={handleTimeline}
        value={historyDateFilter.timeline}
        variant="outlined"
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarTodayOutlined fontSize="small" />
            </InputAdornment>
          ),
        }}>
        <MenuItem value={1}>Yesterday</MenuItem>
        <MenuItem value={3}>Last 3 Days</MenuItem>
        <MenuItem value={7}>Last 7 Days</MenuItem>
        <MenuItem value={14}>Last 14 Days</MenuItem>
        <MenuItem value={30}>Last 30 Days</MenuItem>
        <MenuItem value={90}>Last 90 Days</MenuItem>
        <MenuItem value="all">All</MenuItem>
      </TextField>
    </>
  );
}

HistoryDateRangePicker.defaultProps = {
  disabled: true,
};
HistoryDateRangePicker.propTypes = {
  disabled: PropTypes.bool,
};

export default HistoryDateRangePicker;
