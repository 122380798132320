import { lazy } from 'react';
import { Assignment } from '@audiencetown/owk-uitk/dist/icons';

const Dashboard = lazy(() => import('./views/Dashboard/components/dashboard'));

const ComingSoon = lazy(() => import('./components/comingSoonPage'));
const ReportsRouter = lazy(() => import('./views/Reports/router'));

const EditProfile = lazy(() =>
  import('./views/User Profile/components/editProfile'),
);

const AdvertisersRouter = lazy(() => import('./views/Advertisers/routers'));
const Users = lazy(() => import('./views/Users/UsersList'));
const Invoicing = lazy(() => import('./views/invoices/router'));

const SettingsRouter = lazy(() => import('./views/Settings/routers'));

const AgenciesRouter = lazy(() => import('./views/Agencies/routers'));
const CampaignHubRouter = lazy(() => import('./views/Campaign hub/router'));

const NotFoundPage = lazy(() => import('./components/NotFoundPage'));

const CreativeHubRouter = lazy(() => import('./views/Creative hub/router'));

const CreateCampaign = lazy(() =>
  import('./views/Requested Campaigns/createCampaign'),
);

const dashboardRoutes = [
  {
    path: '/dashboard/*',
    name: 'Dashboard',
    component: Dashboard,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/comingsoon/*',
    name: 'coming soon',
    component: ComingSoon,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/reports/*',
    name: 'reports',
    icon: Assignment,
    component: ReportsRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/profile/*',
    name: 'Edit Profile',
    component: EditProfile,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/settings/advertisers/*',
    name: 'Advertisers List',
    component: AdvertisersRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/settings/users/*',
    name: 'Users',
    component: Users,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/settings/*',
    name: 'Settings',
    component: SettingsRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/invoicing/*',
    name: 'Invoicing',
    component: Invoicing,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/settings/agencies/*',
    name: 'Agencies List',
    component: AgenciesRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/campaigns/new/*',
    name: 'Create Campaign',
    component: CreateCampaign,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/campaigns/*',
    name: 'Campaign Hub',
    component: CampaignHubRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/creatives/*',
    name: 'Creative Hub',
    component: CreativeHubRouter,
    layout: 'main',
    sidebar: false,
  },
  {
    path: '/notFound/*',
    name: 'Not Found',
    component: NotFoundPage,
    layout: 'main',
    sidebar: false,
  },
];

export default dashboardRoutes;
