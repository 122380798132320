import Geo from './geo';
import Movers from './movers';

import { formatDate, dateParser } from '../../../../helpers/constants.helper';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  text: {
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  flightDate: {
    minWidth: '11rem',
    maxWidth: '11rem',
  },
}));

const DisplayData = ({ data, prop, parentProp, identifier }) => {
  const classes = useStyles();

  return identifier === 'geo' ? (
    parentProp ? (
      <Geo data={data} prop={prop} parentProp={parentProp} />
    ) : (
      <Geo data={data} prop={prop} parentProp={parentProp} />
    )
  ) : identifier === 'movers' ? (
    <Movers data={data} prop={prop} parentProp={parentProp} />
  ) : Array.isArray(data[prop]) ? (
    data[prop]?.map((element, idx) =>
      identifier === 'flights' ? (
        <Grid item container spacing={6}>
          <Grid item>
            <Typography
              variant="subtitle2"
              color={prop === 'added' ? 'primary' : 'secondary'}
              className={`${classes.text} ${
                prop === 'deleted' ? classes.lineThrough : ''
              } ${classes.flightDate}`}>
              {identifier === 'flights'
                ? `${formatDate(dateParser(element?.startDate))} - ${formatDate(
                    dateParser(element?.endDate),
                  )}`
                : `${element} `}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              color={prop === 'added' ? 'primary' : 'secondary'}
              className={`${classes.text} ${
                prop === 'deleted' ? classes.lineThrough : ''
              }`}>
              {`$${element?.budget}`}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Typography
            variant="subtitle2"
            color={prop === 'added' ? 'primary' : 'secondary'}
            className={`${classes.text} ${
              prop === 'deleted' ? classes.lineThrough : ''
            }`}>
            {identifier === 'channels'
              ? `${element?.label} ${element?.allocate}% ${
                  idx === data[prop].length - 1 ? '' : ','
                }`
              : identifier === 'pricing'
              ? `${element?.key} ${element?.cpm} ${
                  idx === data[prop].length - 1 ? '' : ','
                }`
              : `${element}${idx === data[prop].length - 1 ? '' : ','} `}
          </Typography>
        </Grid>
      ),
    )
  ) : Array.isArray(data) ? (
    data.map((element, idx) => (
      <Grid item>
        <Typography
          variant="subtitle2"
          color={prop === 'added' ? 'primary' : 'secondary'}
          className={`${classes.text} ${
            prop === 'deleted' ? classes.lineThrough : ''
          }`}>
          {identifier === 'channels'
            ? `${element?.label} ${element?.allocate}% ${
                idx === data[prop].length - 1 ? '' : ','
              }`
            : identifier === 'pricing'
            ? `${element?.key} ${element?.cpm} `
            : `${element} ${idx === data?.length - 1 ? '' : ','}`}
        </Typography>
      </Grid>
    ))
  ) : identifier === 'pricing' ? (
    data[prop][identifier]?.map(element => (
      <Grid item>
        <Typography
          variant="subtitle2"
          color={prop === 'added' ? 'primary' : 'secondary'}
          className={classes.text}>
          {`${element?.key} ${element?.cpm} `}
        </Typography>
      </Grid>
    ))
  ) : (
    <Grid item container spacing={2}>
      <Grid item>
        <Typography
          variant="subtitle2"
          color={prop === 'added' ? 'primary' : 'secondary'}
          className={`${classes.text} ${classes.lineThrough}`}
          style={{
            maxWidth: '100%',
            wordWrap: 'break-word',
            textDecoration: 'line-through',
          }}>
          {identifier === 'endDate' || identifier === 'startDate' ? (
            data[prop]?.oldValue ? (
              `${formatDate(dateParser(data[prop]?.oldValue))} `
            ) : (
              <></>
            )
          ) : identifier === 'budget' ? (
            data[prop]?.oldValue ? (
              `$${data[prop]?.oldValue}`
            ) : prop === 'deleted' ? (
              `$${data[prop]}`
            ) : (
              <></>
            )
          ) : identifier === 'campaignName' ? (
            data[prop]?.oldValue ? (
              `${data[prop]?.oldValue} `
            ) : (
              <></>
            )
          ) : (
            `${data[prop]?.oldValue}`
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle2"
          color={'primary'}
          className={classes.text}>
          {identifier === 'endDate' || identifier === 'startDate' ? (
            data[prop]?.newValue ? (
              `${formatDate(dateParser(data[prop]?.newValue))}`
            ) : (
              `${formatDate(dateParser(data[prop]))}`
            )
          ) : identifier === 'campaignName' ? (
            data[prop]?.newValue ? (
              `${data[prop]?.newValue}`
            ) : (
              `${data[prop]}`
            )
          ) : identifier === 'budget' ? (
            data[prop]?.newValue ? (
              `$${data[prop]?.newValue}`
            ) : prop !== 'deleted' ? (
              `$${data[prop]}`
            ) : (
              <></>
            )
          ) : (
            `${data[prop]?.newValue}`
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DisplayData;
