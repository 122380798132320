import React, { useContext, useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { userContext } from '../userContext';
import { BreadcrumbsContext } from '../breadcrumbsContext';
import { NotificationsContext } from '../notificationContext';
import Notifications from '../views/Notifications/notifications';
import config from '../config';
import {
  getNotifications,
  readNotifications,
} from '../views/Notifications/services/notifications.service';

import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Badge from '@audiencetown/owk-uitk/dist/components/Badge';
import ListItemIcon from '@audiencetown/owk-uitk/dist/components/ListItemIcon';
import Navbar from '@audiencetown/owk-uitk/dist/components/Navbar';
import NavbarItem from '@audiencetown/owk-uitk/dist/components/NavbarItem';
import Slide from '@audiencetown/owk-uitk/dist/components/Slide';
import List from '@audiencetown/owk-uitk/dist/components/List';
import Drawer from '@audiencetown/owk-uitk/dist/components/Drawer';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import {
  DashboardOutlined,
  NotificationsOutlined,
  ExitToAppOutlined,
  BarChartOutlined,
  SettingsOutlined,
  ReceiptOutlined,
  FlagOutlined,
  ImageOutlined,
} from '@audiencetown/owk-uitk/dist/icons';
import { ReactComponent as ReportIcon } from '../images/reportIcon.svg';
import SvgIcon from '@audiencetown/owk-uitk/dist/components/SvgIcon';

const SideNavbar = () => {
  const sideMenu = [
    {
      name: 'Home',
      icon: <DashboardOutlined />,
      path: '/dashboard',
    },
    {
      name: 'Performance',
      icon: <BarChartOutlined />,
      path: '/reports/performance',
    },
    {
      name: 'Campaigns',
      icon: <FlagOutlined />,
      path: '/campaigns',
    },
    process.env.REACT_APP_ENV !== 'prod'
      ? {
          name: 'Reports',
          icon: <SvgIcon component={ReportIcon} />,
          path: '/reports',
        }
      : undefined,
    {
      name: 'Creative Hub',
      icon: <ImageOutlined />,
      path: '/creatives',
    },
    {
      name: 'Invoicing',
      icon: <ReceiptOutlined />,
      path: '/invoicing',
    },
    {
      name: 'Settings',
      icon: <SettingsOutlined />,
      path: '/settings',
    },
  ].filter(a => a);

  const navigate = useNavigate();
  const cookie = new Cookies();
  const { user } = useContext(userContext);
  const [isOpen, setIsOpen] = useState(true);
  const [isPulled, setIsPulled] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const {
    updateNotificationContext: updateNotification,
    setUpdateNotificationContext: setUpdateNotification,
  } = useContext(NotificationsContext);
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [noMoreNotifications, setNoMoreNotifications] = useState(false);

  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);
  const { navSelected, setNavSelected } = useContext(BreadcrumbsContext);
  const { pathname: currentPathname } = useLocation();

  useEffect(() => {
    const fetchNotifications = async () => {
      setNotificationsLoading(true);
      const { notifications, unRead } = await getNotifications(0, 100);
      if (notifications.length < 100) setNoMoreNotifications(true);
      setUnreadCount(unRead.count);
      setNotifications(notifications);
      setNotificationsLoading(false);
      setOffset(0);
      setNoMoreNotifications(false);
    };
    fetchNotifications();

    sideMenu.map(menu =>
      currentPathname.includes(menu.path)
        ? setNavSelected(menu.name)
        : setNavSelected(
            sideMenu.filter(route =>
              window.location.pathname.includes(route?.path),
            )[0]?.name,
          ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPathname, updateNotification, isPulled]);

  const { domain, secure, urlLogin } = config;

  function handleLogout() {
    cookie.remove('at-auth', {
      path: '/',
      domain,
      secure,
    });
    window.location.href = urlLogin;
  }

  const readNotificationsHandler = () =>
    readNotifications(notifications.filter(item => !item.isRead));

  const loadMoreHandler = async () => {
    setLoadMoreLoading(true);
    const data = await getNotifications(offset + 100, 100);
    setOffset(offset + 100);
    setUnreadCount(data.unRead.count);
    readNotifications(data.notifications.filter(item => !item.isRead));
    if (data.notifications.length < 100) setNoMoreNotifications(true);
    setNotifications([...notifications, ...data.notifications]);
    setLoadMoreLoading(false);
  };

  const editNotificationsHandler = notifications =>
    setNotifications(notifications);

  return (
    <>
      <Navbar
        name={
          <Typography style={{ maxWidth: 180 }} noWrap={true}>
            {user.userData.name}
          </Typography>
        }
        onSettingsClick={() => {
          setNavSelected('My Profile');
          navigate('/profile');
        }}
        onOpenStatusChanged={({ open }) => {
          setIsOpen(open);
        }}>
        <Slide direction="right" in style={{ transitionDelay: '150ms' }}>
          <List component="nav" aria-label="menu" style={{ height: '0rem' }}>
            {sideMenu.map((item, index) => (
              <NavbarItem
                data-testid={item.name}
                selected={item.name === navSelected}
                onClick={() => {
                  setNavSelected(item.name);
                  navigate(item.path);
                  breadcrumbsItems?.slice(0, 1)?.length === 2 &&
                    setBreadcrumbsItems(breadcrumbsItems.slice(0, 1));
                }}
                key={index}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                {isOpen ? <ListItemText>{item.name}</ListItemText> : <></>}
              </NavbarItem>
            ))}
            <NavbarItem
              onClick={() => {
                setIsPulled(true);
                readNotificationsHandler();
              }}
              key="altMenu">
              {isOpen ? (
                <>
                  <ListItemIcon>
                    <NotificationsOutlined />
                  </ListItemIcon>
                  <ListItemText>
                    <div style={{ padding: '2px', marginTop: '4px' }}>
                      <Grid
                        spacing={0}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        Notifications
                        {unreadCount > 0 ? (
                          <Badge
                            color="primary"
                            badgeContent={unreadCount}
                            max={99}
                          />
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </div>
                  </ListItemText>
                </>
              ) : (
                <ListItemIcon>
                  {unreadCount > 0 ? (
                    <Badge color="primary" badgeContent={unreadCount} max={99}>
                      <NotificationsOutlined />
                    </Badge>
                  ) : (
                    <NotificationsOutlined />
                  )}
                </ListItemIcon>
              )}
            </NavbarItem>

            <NavbarItem
              data-testid="logout"
              title="Logout"
              onClick={() => handleLogout()}
              key="logout">
              <ListItemIcon>
                <ExitToAppOutlined />
              </ListItemIcon>
              {isOpen ? <ListItemText>Logout</ListItemText> : ''}
            </NavbarItem>
          </List>
        </Slide>
      </Navbar>
      <Drawer
        anchor="left"
        color="secondary"
        open={isPulled}
        onClose={async () => {
          setIsPulled(false);
          setUpdateNotification(!updateNotification);
        }}>
        <Notifications
          setIsPulled={setIsPulled}
          notifications={notifications}
          editNotificationsHandler={editNotificationsHandler}
          loading={notificationsLoading}
          readNotifications={readNotificationsHandler}
          loadMoreHandler={loadMoreHandler}
          loadMoreLoading={loadMoreLoading}
          noMoreNotifications={noMoreNotifications}
        />
      </Drawer>
    </>
  );
};

export default SideNavbar;
