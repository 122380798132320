import Tooltip from '@audiencetown/owk-uitk/dist/components/Tooltip';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';

const NotificationUserName = ({ item }) => (
  <>
    {item?.rawData?.user?.name?.length > 18 ? (
      <Tooltip title={item?.rawData?.user?.name} arrow>
        <Typography>
          {`${item?.rawData?.user?.name.slice(0, 18)}  ...`}
        </Typography>
      </Tooltip>
    ) : (
      <Typography>
        {item?.from ? item?.rawData?.user?.name : 'System'}
      </Typography>
    )}
  </>
);

export default NotificationUserName;
