import axios from 'axios';

export const HistoryService = {
  getCampaignHubHistoryById: async (id, startDate, endDate) =>
    axios
      .get(`campaign-request/${id}/audit/`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then(res => res.data),

  getAuditHistoryByInvoiceId: async (id, startDate, endDate) =>
    axios
      .get(`/invoice/${id}/audit/`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then(res => res.data),
  getBillingHistoryByInvoiceId: async (id, startDate, endDate) =>
    axios
      .get(`invoice/${id}/billing/history/`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then(res => res.data),
  getCreativeHistoryById: async (id, startDate, endDate) =>
    axios.get(`creative/${id}/audit/`).then(res => res.data),
};
