import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';

import config from '../config';

const cookie = new Cookies();

export const refreshTokenApi = async (refreshToken, token, setUser) => {
  try {
    const { data } = await axios.put('auth/token/refresh', {
      refreshToken,
    });
    const timer = moment().add(45, 'minutes');
    cookie.set(
      'at-auth',
      { ...data, timer },
      {
        maxAge: 3600,
        domain: config.domain,
        secure: config.secure,
      },
    );
  } catch (e) {
    setUser({ loggedIn: false });
    cookie.remove('at-auth', {
      domain: config.domain,
      secure: config.secure,
    });
    window.location.href = `${config.urlLogin}?returnUrl=${encodeURIComponent(
      window.location.href.replace(window.location.origin, ''),
    )}`;
  }
};
