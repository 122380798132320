import { React } from 'react';

import Chip from '@audiencetown/owk-uitk/dist/components/Chip';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  pending: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  submitted: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  approved: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  published: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  live: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  paused: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  cancelled: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
}));

const Status = ({ label, Icons }) => {
  const classes = useStyles();

  return (
    <>
      {label ? (
        <Chip
          label={label?.charAt(0).toUpperCase() + label?.slice(1)}
          variant="outlined"
          size="small"
          icon={
            Icons ? (
              <Icons
                fontSize="small"
                className={label ? classes[label] : ''}
                style={{ paddingLeft: '0.2rem' }}
              />
            ) : (
              <> </>
            )
          }
          className={label ? classes[label] : ''}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default Status;
