import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { CampaignRequestAuditService } from '../../../views/Campaign hub/services';
import RenderItemsHistory from './publishedHistory/renderItemsHistory';
import { formatFullDateTime } from '../../../helpers/constants.helper';
import HistoryListSkeleton from './HistoryListSkeleton';

import { objectComparer } from '../../../helpers';

import Grid from '@audiencetown/owk-uitk/dist/components/Grid';
import Typography from '@audiencetown/owk-uitk/dist/components/Typography';
import ListItem from '@audiencetown/owk-uitk/dist/components/ListItem';
import ListItemText from '@audiencetown/owk-uitk/dist/components/ListItemText';
import ListItemAvatar from '@audiencetown/owk-uitk/dist/components/ListItemAvatar';
import Divider from '@audiencetown/owk-uitk/dist/components/Divider';
import ProfileImage from '@audiencetown/owk-uitk/dist/assets/profile.svg';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.secondary.gray,
    marginTop: '1rem',
  },
  root: {
    marginRight: '1rem',
  },
}));

const CampaignPublishedHistory = ({
  id,
  datesFilters,
  handleLoadingChange,
  isLoading,
}) => {
  const classes = useStyles();
  const [diff, setDiff] = useState();

  useEffect(() => {
    const fetchHistory = async () => {
      handleLoadingChange(true);
      const campaignRequestAudit = await CampaignRequestAuditService.get(
        id,
        datesFilters?.customRange[0],
        moment(datesFilters?.customRange[1])
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      );

      const creatives = campaignRequestAudit
        .filter(el => el.action === 'UPDATE_CREATIVE')
        .map(creative => ({
          name: creative?.metadata?.user?.name,
          date: creative?.date,
          creativeDiff: creative?.newValue?.length
            ? creative?.newValue?.map((el, idx) => ({
                ...objectComparer(
                  creative?.oldValue[idx]?.metadata
                    ? creative?.oldValue[idx]?.metadata
                    : {},
                  el?.metadata ? el.metadata : {},
                ),
                action: creative?.action ?? '',
              }))
            : creative?.oldValue?.length
            ? creative?.oldValue?.map((el, idx) => ({
                ...objectComparer(
                  el?.metadata ? el.metadata : {},
                  creative?.newValue[idx]?.metadata
                    ? creative?.newValue[idx]?.metadata
                    : {},
                ),
                action: creative?.action ?? '',
              }))
            : [],
        }))
        .map(creative =>
          creative?.creativeDiff?.length
            ? {
                ...creative,
                creativeDiff: creative?.creativeDiff?.filter(
                  el =>
                    Object.keys(el).length &&
                    Object.keys(el).some(
                      e =>
                        e === 'name' ||
                        e === 'landingPage' ||
                        e === 'clickThroughUrl',
                    ),
                ),
              }
            : { ...creative, creativeDiff: [] },
        )
        .filter(creative => creative?.creativeDiff?.length);

      const requestDataAudit = campaignRequestAudit
        .filter(el => el?.action !== 'UPDATE_CREATIVE')
        .map(el => ({
          ...el,
          newValue: {
            ...el?.newValue,
            rawData: {
              ...el?.newValue?.rawData,
              campaignName: el?.newValue?.name,
            },
          },
          oldValue: {
            ...el?.oldValue,
            rawData: {
              ...el?.oldValue?.rawData,
              campaignName: el?.oldValue?.name,
            },
          },
          action: el?.action ?? '',
        }))
        .map(el => {
          return {
            ...objectComparer(
              el?.oldValue ? el?.oldValue?.rawData : {},
              el?.newValue ? el?.newValue?.rawData : {},
            ),
            name: el?.metadata?.user?.name,
            date: el?.date,
            action: el?.action ?? '',
          };
        })
        .filter(el => Object.keys(el).length > 2);

      setDiff(
        [...requestDataAudit, ...creatives].sort((a, b) =>
          a.date > b.date ? -1 : 1,
        ),
      );

      handleLoadingChange(false);
    };
    fetchHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, datesFilters]);

  return isLoading ? (
    <HistoryListSkeleton />
  ) : (
    <>
      {diff?.map(element => (
        <>
          <ListItem alignItems="center" justifyContent="center">
            <ListItemAvatar>
              <img
                style={{ width: '2.5rem', height: '2.5rem' }}
                src={ProfileImage}
                alt="user profile"
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">{element?.name}</Typography>
                  </Grid>
                  <Grid item xs={6} align="right">
                    <Typography variant="subtitle2">
                      {` ${formatFullDateTime(element?.date)}`}
                    </Typography>
                    <Typography
                      style={{ textTransform: 'capitalize' }}
                      variant="subtitle2">
                      {`${element?.action?.toLowerCase() ?? ''}`}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </ListItem>
          {Object.keys(element).map(el => (
            <RenderItemsHistory data={element} value={el} />
          ))}
          <Divider variant="middle" className={classes.divider} />
        </>
      ))}
    </>
  );
};

export default CampaignPublishedHistory;
